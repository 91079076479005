import { BirthdayStorageValue } from '@/composition/birthdayMessages';
import { MessageStorageValue } from './message';
import { Season } from './auth';
import { SubscriptionPlan } from './payment';
import { download } from '@/admin/file';
import { ProductResponse } from './product';

export class DisplayDate {
  value: number | undefined;

  constructor(unixTime?: number) {
    this.value = unixTime;
  }

  get date() {
    return this.format('YYYY-MM-DD');
  }

  get dateTime() {
    return this.format('YYYY-MM-DD hh:mm');
  }

  get dateTimeSeconds() {
    return this.format('YYYY-MM-DD hh:mm:ss');
  }

  get time() {
    return this.format('hh:mm');
  }

  get timeSeconds() {
    return this.format('hh:mm:ss');
  }

  static now() {
    return new DisplayDate(Date.now());
  }

  static setHours() {
    return new DisplayDate(new Date().setHours(0, 0, 0, 0));
  }

  static parse(str: string) {
    try {
      const date = new Date(str.replace(/-/g, '/'));
      return new DisplayDate(date.getTime());
    } catch (error) {
      console.error(error);
    }
  }

  format(str?: string) {
    if (this.value !== undefined) {
      str = str || 'YYYY-MM-DD';
      const date = new Date(this.value);
      const YYYY = date.getFullYear().toString();
      const MM = (date.getMonth() + 1).toString().padStart(2, '0');
      const DD = date
        .getDate()
        .toString()
        .padStart(2, '0');
      const hh = date
        .getHours()
        .toString()
        .padStart(2, '0');
      const mm = date
        .getMinutes()
        .toString()
        .padStart(2, '0');
      const ss = date
        .getSeconds()
        .toString()
        .padStart(2, '0');
      const M = (date.getMonth() + 1).toString();
      const D = date.getDate().toString();
      str = str.replace('YYYY', YYYY);
      str = str.replace('MM', MM);
      str = str.replace('DD', DD);
      str = str.replace('hh', hh);
      str = str.replace('mm', mm);
      str = str.replace('ss', ss);
      str = str.replace('M', M);
      str = str.replace('D', D);
      return str;
    } else {
      return '';
    }
  }
}
export const createDatePeriod = (
  startDate: number | undefined,
  endDate: number | undefined,
  format: 'date' | 'dateTime' | 'dateTimeSeconds' = 'dateTime'
) => {
  let datePeriod = '';
  const startDisplayDate = new DisplayDate(startDate);
  const endDisplayDate = new DisplayDate(endDate);
  if (startDisplayDate.value !== undefined) datePeriod += startDisplayDate[format];
  if (startDisplayDate.value !== undefined && endDisplayDate.value !== undefined) datePeriod += ' - ';
  if (endDisplayDate.value !== undefined) datePeriod += endDisplayDate[format];
  return datePeriod;
};
// eslint-disable-next-line
export const getValueByPath = (object: any, path: string) => {
  const keys = path.split('.');
  let index = 0;
  do {
    object = object[keys[index]];
    index++;
  } while (object !== undefined && index < keys.length);
  return object;
};

// eslint-disable-next-line
export const downloadCsv = (
  headers: { text: string; value: string }[],
  entries: { [key: string]: any }[],
  filename?: string
) => {
  let csv = '';

  // ヘッダ行
  csv += headers.map((header) => header.text).join(',') + '\r\n';

  // データ行
  entries.forEach((entry) => {
    const data: string[] = [];
    headers.forEach((header) => {
      data.push(getValueByPath(entry, header.value) ?? '');
    });
    csv +=
      data
        .map((value) => {
          // 入力文にダブルクォートが含まれていた場合の対策
          if (typeof value === 'string') value = value.replace(/"/g, '”');
          // 入力文にカンマが含まれていた場合の対策
          return `"${value}"`;
        })
        .join(',') + '\r\n';
  });

  // Blob作成
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csv], { type: 'text/csv' });

  // csvダウンロード処理
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = filename || 'data.csv';
  a.click();
  a.parentNode?.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const getOptionsTitle = (
  options: {
    text: string;
    value: string;
  }[],
  value?: string
) => options.find((option) => option.value === value)?.text || '';

export const getElapsedDate = (number: number) => {
  const year = number >= 12 ? `${Math.floor(number / 12)}年` : '';
  const month = `${number % 12}ヶ月`;
  return year + month;
};

export const getTargetPlanNames = (targetPlanIds: string[], plans: SubscriptionPlan[]): string[] => {
  const _plans = plans;
  const targetPlanNames = targetPlanIds.map((subscriptionPlanId) => {
    const subscriptionPlan = _plans.find((plan) => plan.subscriptionPlanId === subscriptionPlanId);
    return subscriptionPlan
      ? subscriptionPlan.isArchive
        ? `${subscriptionPlan.subscriptionPlanName}(削除済み)`
        : subscriptionPlan.subscriptionPlanName
      : `存在しないプラン(${subscriptionPlanId})`;
  });
  return targetPlanNames;
};
export const getTargetPlanName = (targetPlanId: string, plans: SubscriptionPlan[]): string => {
  const subscriptionPlan = plans.find((plan) => plan.subscriptionPlanId === targetPlanId);
  return subscriptionPlan
    ? subscriptionPlan.isArchive
      ? `${subscriptionPlan.subscriptionPlanName}(削除済み)`
      : subscriptionPlan.subscriptionPlanName
    : `存在しないプラン(${targetPlanId})`;
};

export const getTargetSeasonName = (targetSeasonIds: number[], seasonIds: Season[]): string => {
  const _seasonIds = seasonIds;
  const _seasonsData = (() => {
    const thisSeason = _seasonIds.find((season) => season.isActive) || null;
    if (thisSeason) {
      return _seasonIds.map((season) => {
        let text = '';
        const value: number[] = [];
        if (thisSeason.seasonId <= season.seasonId) {
          text = season.isActive
            ? `${season.seasonName}（アクティブシーズン）`
            : `${season.seasonName}（シーズン予約）`;
          value.push(season.seasonId);
        } else {
          text = `${season.seasonName}（${season.seasonName}~${thisSeason.seasonName}）`;
          for (let i = season.seasonId; i <= thisSeason.seasonId; i++) {
            value.push(i);
          }
        }
        return {
          text,
          value,
        };
      });
    } else return [];
  })();
  return _seasonsData.find((item) => item.value.toString() === targetSeasonIds.toString())?.text || '';
};

export const convertWysiwygTextForDisplay = async (text?: string) => {
  if (!text) return '';

  // 画像URLの抽出
  const hostingUrl = process.env.VUE_APP_HOSTING_URL;
  const imageTags = text.match(/<img.*?src=".*?"/g) || [];
  const imageUrls = imageTags
    .map((imageTag) => imageTag.match(/<img.*?src="(.*?)"/)?.[1])
    .filter((imageUrl) => imageUrl && (imageUrl.match(hostingUrl) || imageUrl.match('tmp/images/'))) as string[];

  // ドメイン部分を削除して署名付URLをリクエスト
  const requests = imageUrls.map((imageUrl) => download(imageUrl.replace(`${hostingUrl}/`, '')));
  const signedUrls = await Promise.all(requests);

  // 画像URLを署名付URLに置き換え
  imageUrls.forEach((imageUrl, i) => {
    text = (text as string).replace(imageUrl, signedUrls[i]);
  });

  return text;
};

export const convertWysiwygTextForSave = (text: string) => {
  // 新たにアップロードされた画像の一覧を格納する配列
  const images: string[] = [];

  // 署名付きURLの抽出
  const imageTags = text.match(/<img.*?src=".*?"/g) || [];
  const imageUrls = imageTags
    .map((imageTag) => imageTag.match(/<img.*?src="(.*?)"/)?.[1])
    .filter((imageUrl) => imageUrl && imageUrl.match('.s3.ap-northeast-1.amazonaws.com')) as string[];

  imageUrls.forEach(async (imageUrl) => {
    const url = new URL(imageUrl);
    if (imageUrl.match('/tmp/images/')) {
      // 新たにアップロードされた画像の場合
      const path = url.pathname.slice(1);
      text = text.replace(imageUrl, path);
      images.push(path);
    } else {
      // 保存済みの画像の場合
      const hostingUrl = process.env.VUE_APP_HOSTING_URL;
      text = text.replace(imageUrl, hostingUrl + url.pathname);
    }
  });

  return { text, images };
};
export const getTargetProductName = (targetProductId: string, products: ProductResponse[]): string => {
  const product = products.find((product) => product.productId === targetProductId);
  return product
    ? product.isArchive
      ? `${product.productName}(削除済み)`
      : product.productName
    : `存在しない商品(${targetProductId})`;
};

export type valueOf<T> = T[keyof T];
export function getKeyByValue<T extends { [key in string | number]: string }>(obj: T, value: valueOf<T>): keyof T {
  for (const [_key, _value] of Object.entries(obj)) {
    if (_value === value) return _key;
  }
  throw '不明な値です';
}

// ストレージ操作用
type StorageContentsType = 'message' | 'birthday';
type StorageValueType<T extends StorageContentsType> = T extends 'message' ? MessageStorageValue : BirthdayStorageValue;
export function saveStorage<T extends StorageContentsType>(storageKey: string, value: StorageValueType<T>) {
  window.localStorage.setItem(storageKey, JSON.stringify(value));
}
export function getStorageValue<T extends StorageContentsType>(storageKey: string): StorageValueType<T> | undefined {
  const jsonString = window.localStorage.getItem(storageKey);
  if (jsonString) return JSON.parse(jsonString) as StorageValueType<T>;
  return undefined;
}
export function removeStorage(storageKey: string) {
  if (window.localStorage.getItem(storageKey)) window.localStorage.removeItem(storageKey);
}
